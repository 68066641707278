<template>
    <div class="Details">
        <div class="title">
           {{information.matchName}}
        </div> 
        <div class="tip">
            <div class="date">
                <van-icon name="underway-o" />
                <span>
                    {{information.begDate}} ~ {{information.endDate}}
                </span>
            </div>
        </div>
        <div class="content-desc" v-if="information.matchItem" v-html="information.matchItem"> </div>

        <div class="tips" v-if="fileList.length !== 0">
            <span>精彩瞬间</span>
        </div>

        <div class="image-list" v-if="fileList.length !== 0">
            <van-uploader class="my-upload" v-model="fileList" multiple :deletable="false" :show-upload="false" />
        </div>

        <div class="tips" v-if="videoList.length !== 0">
            <span>精彩视频</span>
        </div>

        <div class="videoList" v-if="videoList.length !== 0">
            <div class="videoItem" v-for="(item, index) in videoList" :key="index" @click="openVideo(item)">
                <img class="video" :src="item.picUrl" >
                <img class="img" v-if="item.isImage" :src="palyUrl" alt="">
            </div>
        </div>

        <van-popup class="video_popup" v-model="showvideoplay" theme="default" @close="closePop">
            <video class="video" ref="video" controls preload="auto" :src="videoUrl" v-if="videoUrl"></video>
        </van-popup>

       
    </div>
</template>

<script>
export default {
    data() {
        return {
            //videoUrl: require('@/assets/img/94.mp4')
            fileList: [],
            information: {},
            videoList: [],
            videoUrl: '',
            showvideoplay: false,
            palyUrl: require('@/assets/img/play.png'),

        }
    },
    methods: {
        getDetails() {
            this.$help.loading()
            this.$api.post('common/base/match/match-show',this.$route.query).then(res=>{
                //console.log(res)
                let picList = []
                if(res.picUrl){
                    picList = res.picUrl.split(','); 
                    picList.forEach(ele=>{
                        if(ele.indexOf('.mp4') !== -1){
                            // console.log(ele)
                            this.videoList.push({
                                url: this.getImg(ele),
                                isImage: true
                            })
                        }else{
                            this.fileList.push({url:this.getImg(ele), isImage: true })
                        }
                        
                    })
                }
                this.videoList.map(ele=>{
                    this.getVideoBase64(ele.url).then(res=>{
                        this.$set(ele, 'picUrl', res)
                    })
                })
                console.log(this.videoList)
                this.information = res
            })
            .finally(this.$help.loaded)
        },

        getVideoBase64(url) {
            //debugger
            return new Promise((resolve) => {

                let dataURL = '';
                let video = document.createElement("video");

                // 处理跨域
                video.setAttribute('crossOrigin', 'anonymous');
                video.setAttribute('src', url);
                video.setAttribute('width', 100);
                video.setAttribute('height', 100);
                video.setAttribute('autoplay', 'autoplay');
                video.pause();
                video.addEventListener('loadeddata', () => {
                    // 视频时长video.duration;
                    let canvas = document.createElement("canvas"),
                        //canvas的尺寸和图片一样
                        width = video.width, 
                        height = video.height;

                    canvas.width = width;
                    canvas.height = height;
                    // 绘制canvas
                    canvas.getContext("2d").drawImage(video, 0, 0, width, height);
                    // 转换为base64
                    dataURL = canvas.toDataURL('image/jpeg'); 
                    resolve(dataURL);
                });
            });
        },

        openVideo(item){
            this.showvideoplay = true;
            this.videoUrl = item.url
            this.$nextTick(()=>{
                this.$refs.video.play()
            })
            this.videoList.map(ele=>{
                if(ele.url === item.url){
                    ele.isImage = false
                }
            })
        },
        closePop() {
            this.videoList.map(ele=>{
                ele.isImage = true
            })
            this.$refs.video.pause()
            // console.log(this.$refs.video)
        }
    },
    mounted() {
        //console.log(this.$route.query.id)
        this.getDetails()
    }
}
</script>

<style lang="scss">
    @import '~@/assets/css/var';
    .Details{
        .title{
            font-size: 16px;
            font-weight: bold;
            padding: 10px;
            //background: $backgroundColor;
        }
        .tip{
            display: flex;
            justify-content: space-between;
            margin: 10px;
            border-radius: 9px;
            //padding: 10px 0; 
            color: $gray;
            // .van-icon{
            //     color: $main;
            // }
        }
        .content-desc{
            padding: 20px;
            margin: 20px 15px;
            box-shadow: 0 0 9px 6px $boxShadow;
            border-radius: 19px;
            background-color: #fff;
        }
        .tips{
            margin-top: 20px;
            span{
                display: inline-block;
                background-color: $main;
                padding: 10px 20px;
                color: #fff;
                font-size: 14px;
                border-radius: 0 28px 28px 0;
            }
        }
        .image-list{
            padding: 20px;
            background-color: #fff;
            margin-top: 20px;
            .my-upload {
                flex: 1;
                margin-bottom: 20px;
                .van-uploader__wrapper {
                    justify-content: flex-start;
                    
                    .van-uploader__preview{
                        margin-right: 8px;
                        margin-bottom: 8px;
                    }
                }
                .van-uploader__upload, .van-uploader__preview, .van-uploader__preview-image {
                    width: 100px;
                    margin: 0;
                    height: 100px;
                }

            }
        }
        .videoList{
            padding: 5px;
            background: #fff;
            display: flex;
            .videoItem{
                margin: 10px 0 0 10px ; 
                position: relative;
                .video{
                    width: 110px;
                    height: 110px;
                    object-fit:cover; 
                    background-color: rgba(0, 0, 0, .3);
                }
                .img{
                    width: 40px;
                    height: 40px;
                    position: absolute;
                    padding: unset;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    border-radius: 50%;
                    
                }
            }
        }
        .video_popup{
            width:100%;
            border-radius :0;
            height: 200px;
            .video{
                width: 100%;
                height: 200px;
                object-fit: contain;
            }
        }
    }

</style>